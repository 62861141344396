@use '@shared/util-styling/mixins';

.profile-photo {
  $_badge-size-small: 64px;
  $_badge-size: 80px;

  border-radius: 50%;
  position: relative;

  &__badge {
    align-items: center;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: $_badge-size-small;
    justify-content: center;
    position: absolute;
    right: 0;
    width: $_badge-size-small;
  }

  @include mixins.breakpoint('medium') {
    &__badge {
      height: $_badge-size;
      width: $_badge-size;
    }
  }
}
